const Sentry = require("@sentry/nextjs");
const GLOVE_TESTS_VARIANT_PREFIX = "new_glove_test";
const getGloveActiveTests = (abTests)=>abTests.filter((test)=>test.variant.startsWith(GLOVE_TESTS_VARIANT_PREFIX));
const getGloveActiveTestNames = (abTests)=>getGloveActiveTests(abTests).map((test)=>test.name);
// Save the test names directly to the window object
const saveTestNamesToWindow = (abTests)=>{
    if (typeof window === "undefined") {
        Sentry.captureMessage("saveTestNamesToWindow called on server side. Skipping initialization.");
        return;
    }
    if (!abTests) abTests = [];
    window.activeGloveTests = getGloveActiveTestNames(abTests);
};
export default saveTestNamesToWindow;
